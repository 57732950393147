<template>
  <div>
    <slot />
  </div>
</template>

<script setup>
useHead({
  htmlAttrs: {
    class: "bg-orange-50",
  },
  titleTemplate: "%s - Ultrafedt POS Kitchen",
});
</script>
